import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

const MusicPage = ({ data }) => {
  return (
    <Layout title="Music">
      <SEO title="Music | Leonie Freudenberger" />
      <article className="tabbed-content">
        <div className="music-content">
          {data.allContentfulMusic.nodes.map(music => (
            <div
              className="entry fade-in-1"
              data-sal="fade"
              data-sal-duration="1000"
            >
              <h4>{music.title}</h4>
              {music.credits !== null && (
                <div
                  className="credits"
                  dangerouslySetInnerHTML={{
                    __html: music.credits.childMarkdownRemark.html,
                  }}
                ></div>
              )}
              {music.description !== null && (
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: music.description.childMarkdownRemark.html,
                  }}
                ></div>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: music.embedCode.childMarkdownRemark.html,
                }}
              ></div>
            </div>
          ))}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulMusic(sort: { fields: releaseDate, order: DESC }) {
      nodes {
        title
        credits {
          childMarkdownRemark {
            html
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        embedCode {
          childMarkdownRemark {
            html
          }
        }
        releaseDate(formatString: "YYYY")
      }
    }
  }
`

export default MusicPage
